import {
   MarketingAvailabilityInitialDataResponse,
   MarketingAvailabilitySearchCliniciansDto,
   MarketingAvailabilitySearchCliniciansResponse,
   marketingAvailabilitySearchCliniciansSchema,
} from '@innerwell/dtos';
import { initContract } from '@ts-rest/core';

const c = initContract();
export const marketingAvailability = c.router(
   {
      getInitialData: {
         method: 'GET',
         path: '/marketing-availability/initial-data',
         responses: {
            200: c.type<MarketingAvailabilityInitialDataResponse>(),
         },
         metadata: {
            dontCheckCredentials: true,
         },
      },
      searchClinicians: {
         method: 'GET',
         path: '/marketing-availability/search-clinicians',
         query: marketingAvailabilitySearchCliniciansSchema,
         responses: {
            200: c.type<MarketingAvailabilitySearchCliniciansResponse>(),
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
